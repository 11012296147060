<template>
  <div class="course-details">
    <div
      class="custom-sidebar"
      :style="{ left: isCollapsed ? '90px' : '260px' }"
    >
      <WeeksSidebar
        :page_data="courseDetails"
        @openSection="handleOpneSection"
        :enrollments="enrollments"
      />
    </div>
    <div class="course-content">
      <ShowSection
        :page_data="courseDetails"
        :lesson_id="lesson_id"
        :section_id="section_id"
      />
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import WeeksSidebar from "@/components/classrooms/weeks/weekSidebar/WeeksSidebar.vue";
import ShowSection from "@/components/classrooms/weeks/sections/showSection/ShowSection.vue";

export default {
  name: "CourseDetails",
  components: {
    WeeksSidebar,
    ShowSection,
    BRow,
    BCol,
  },
  data() {
    return {
      course_id: null,
      lesson_id: null,
      section_id: null,
      courseDetails: null,
      enrollments: {},
    };
  },
  computed: {
    isCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
        ? true
        : false;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.course_id = this.$route.params.id;
      this.getCourseDetails();
      this.getDataEnrollments();
    }
  },

  methods: {
    getCourseDetails() {
      this.$http
        .get(`guardian/courses/${this.course_id}?enrolled=true`)
        .then((res) => {
          if (res.status) {
            this.courseDetails = res.data.data;
            console.log(res.data.data);
            this.$store.commit("BreadCrumb/AddBreadcrumb", {
              text: res.data.data.name,
              active: true,
            });

            this.lesson_id = res.data.data.lessons[0].id;
            this.section_id = res.data.data.lessons[0].sections[0].id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDataEnrollments() {
      this.$http
        .get(`guardian/enrollments/${this.course_id}?simple=true`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.enrollments = data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleOpneSection(lesson_id, section_id) {
      this.lesson_id = lesson_id;
      this.section_id = section_id;
    },
  },
};
</script>

<style lang="scss" src="./_courseDetails.scss"></style>
