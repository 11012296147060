var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_data)?_c('div',{staticClass:"weeks-sidebar"},[_c('h2',{staticClass:"pl-1 pt-1 mb-0 course-name",staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.page_data.name))]),_c('div',{staticClass:"week-header py-2 px-1"},[_c('div',{staticClass:"progress-wrapper"},[_c('b-progress',{attrs:{"max":"100","variant":"success"},model:{value:(_vm.page_data.enrollments.progress),callback:function ($$v) {_vm.$set(_vm.page_data.enrollments, "progress", $$v)},expression:"page_data.enrollments.progress"}}),_c('b-card-text',{staticClass:"mb-0 text-header"},[_vm._v(" "+_vm._s(((_vm.page_data.enrollments.progress) + " " + (_vm.$t("g.done"))))+" ")])],1),_c('div',{staticClass:"d-flex justify-content-between grade"},[_c('p',[_vm._v(_vm._s(((_vm.$t("g.scores")) + ": " + (_vm.page_data.enrollments.score))))])]),_c('h3',{staticClass:"course-title"},[_vm._v(_vm._s(_vm.page_data ? _vm.page_data.name : null))])]),_c('div',{staticClass:"week-content"},_vm._l((_vm.page_data.lessons),function(lesson){return _c('b-card',{key:lesson.id,staticClass:"week-box-component",style:({
        border: _vm.activeCollapse === lesson.id ? '1px solid #d9d9d9' : 'none',
      })},[_c('b-card-header',{style:({
          'background-color': lesson.is_locked
            ? '#e3e3e3'
            : _vm.activeCollapse === lesson.id
            ? '#135991'
            : '#fff',
        }),on:{"click":function($event){return _vm.toggleCollapse(lesson.id, lesson.is_locked)}}},[_c('div',{style:({
            color: _vm.activeCollapse === lesson.id ? '#fff' : '#000',
          })},[_c('feather-icon',{staticClass:"text-success fw-bold",attrs:{"icon":"CheckIcon","size":"26"}}),_vm._v(" "+_vm._s(lesson.name)+" ")],1),_c('div',[(!lesson.is_locked)?_c('b-img',{staticClass:"pr-1",attrs:{"src":_vm.activeCollapse === lesson.id ? _vm.infoImg_w : _vm.infoImg,"alt":"info icon"}}):_c('b-img',{staticClass:"pr-1",attrs:{"src":_vm.locked,"alt":"info icon"}}),_c('b-img',{attrs:{"src":_vm.activeCollapse === lesson.id ? _vm.arrowImg_w : _vm.arrowImg,"alt":"arrow icon"}})],1)]),_c('b-collapse',{attrs:{"visible":_vm.activeCollapse === lesson.id}},[_c('b-card-body',{staticClass:"custom-card-body"},_vm._l((lesson.sections),function(section){return _c('b-card-text',{key:section.id},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openSection(lesson.id, section.id)}}},[_c('img',{attrs:{"src":section.is_assignment ? _vm.pen : _vm.group,"alt":"arrow icon"}}),_vm._v(" "+_vm._s(section.name)+" ")])])}),1)],1)],1)}),1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }