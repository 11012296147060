<template>
  <b-row
    v-if="
      $route.meta.breadcrumb ||
      $route.meta.pageTitle ||
      $store.state.BreadCrumb.list
    "
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12">
      <b-row class="breadcrumbs-top">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item
              v-for="item in list"
              :key="item.text"
              :active="item.active"
              :to="item.to"
            >
              {{ $t(item.text) }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return {
      list: null,
    };
  },
  mounted() {
    this.list = this.$store.state.BreadCrumb.list;
  },
  directives: {
    Ripple,
  },

  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
};
</script>
