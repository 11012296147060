<template>
  <div class="sectionAnswers" v-if="page_data.submissions.length > 0">
    <h1>{{ $t('g.answers') }}</h1>

    <div class="answersCard" v-for="(submission, index) in page_data.submissions" :key="submission.id">
      <div class="header d-flex justify-content-between">
        <p>{{ $t('g.submission') }} {{ index + 1 }}</p>
        <p class="grade" v-if="submission.submission_grade">{{ $t('g.grade') }}:
          <span>{{ submission.submission_grade.grade.name + ' ' + submission.submission_grade.score }}</span></p>
      </div>

      <div class="cardContent" v-for="(answer, index) in submission.answer_docs" :key="answer.id">
        <div class="body">
          <div class="answer d-flex justify-content-between">
            <p>{{ answer.name }}</p>
            <a :href="answer.path" target="_blank">{{ $t('g.view') }}</a>
          </div>

          <hr v-if="submission.answer_docs.length !== index + 1"/>
        </div>

        <div class="cardFooter" v-if="submission.submission_grade">
          <div class="d-flex justify-content-between">
            <p>{{ $t('g.corredcted_by') }} <span>{{ submission.submission_grade.teacher.user.full_name }}</span></p>
            <img class="cursor-pointer" :src="collapseBtn" alt="chat" @click="toggleCollapse(index)"
                 v-if="submission.comments"
            />
          </div>
          <b-collapse :id="`collapse-${index}`" class="mt-2">
            <p class="card-text" v-for="comment in submission.comments" :key="comment.id">{{ comment.text }}</p>
          </b-collapse>
        </div>
      </div>

      <p class="hint">{{
          $t('g.answers_hint')
              .replace(':rest_attemps', restAttempts)
              .replace(':attemps', page_data.max_attempts)
        }}</p>
    </div>
  </div>
</template>

<script>
import { BCollapse, BButton } from 'bootstrap-vue'

export default {
  name: 'SectionAnswers',
  components: {
    BCollapse,
    BButton
  },
  props: {
    page_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapseBtn: require('@/assets/images/icons/collapsed.png'),
      isCollapsed: true,
      restAttempts: this.page_data.max_attempts - this.page_data.submissions.length
    }
  },
  methods: {
    toggleCollapse(index) {
      this.$root.$emit('bv::toggle::collapse', `collapse-${index}`)
      this.isCollapsed = !this.isCollapsed
    },
  },
  watch: {
    isCollapsed: function (val) {
      this.collapseBtn = val
          ? require('@/assets/images/icons/collapsed.png')
          : require('@/assets/images/icons/un-collapsed.png')
    },
    restAttempts: function () {
      return this.page_data.max_attempts - this.page_data.submissions.length
    }
  },
}
</script>

<style src="./_sectionAnswers.scss" lang="scss" scoped/>
