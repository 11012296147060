<template>
  <div class="weeks-sidebar" v-if="page_data">
    <h2 class="pl-1 pt-1 mb-0 course-name" style="text-transform: capitalize;">{{ page_data.name }}</h2>
    <div class="week-header py-2 px-1">
      <div class="progress-wrapper">
        <b-progress
          v-model="page_data.enrollments.progress"
          max="100"
          variant="success"
        />
        <b-card-text class="mb-0 text-header">
          {{ `${page_data.enrollments.progress} ${$t("g.done")}` }}
        </b-card-text>
      </div>
      <div class="d-flex justify-content-between grade">
        <p>{{ `${$t("g.scores")}: ${page_data.enrollments.score}` }}</p>
      </div>
      <h3 class="course-title">{{ page_data ? page_data.name : null }}</h3>
    </div>
    <div class="week-content">
      <b-card
        class="week-box-component"
        v-for="lesson in page_data.lessons"
        :key="lesson.id"
        :style="{
          border: activeCollapse === lesson.id ? '1px solid #d9d9d9' : 'none',
        }"
      >
        <b-card-header
          @click="toggleCollapse(lesson.id, lesson.is_locked)"
          :style="{
            'background-color': lesson.is_locked
              ? '#e3e3e3'
              : activeCollapse === lesson.id
              ? '#135991'
              : '#fff',
          }"
        >
          <div
            :style="{
              color: activeCollapse === lesson.id ? '#fff' : '#000',
            }"
          >
            <feather-icon
              icon="CheckIcon"
              size="26"
              class="text-success fw-bold"
            />
            {{ lesson.name }}
          </div>
          <div>
            <b-img
              v-if="!lesson.is_locked"
              :src="activeCollapse === lesson.id ? infoImg_w : infoImg"
              alt="info icon"
              class="pr-1"
            />
            <b-img v-else :src="locked" alt="info icon" class="pr-1" />
            <b-img
              :src="activeCollapse === lesson.id ? arrowImg_w : arrowImg"
              alt="arrow icon"
            />
          </div>
        </b-card-header>
        <b-collapse :visible="activeCollapse === lesson.id">
          <b-card-body class="custom-card-body">
            <b-card-text v-for="section in lesson.sections" :key="section.id">
              <div
                @click="openSection(lesson.id, section.id)"
                style="cursor: pointer"
              >
                <img
                    :src="section.is_assignment ? pen : group"
                    alt="arrow icon"
                />
                {{ section.name }}
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BProgress,
  BCardText,
  BCardHeader,
  BCollapse,
  BCardBody,
  BCard,
  BImg,
} from "bootstrap-vue";
export default {
  name: "WeekSidebar",
  components: {
    BCardText,
    BProgress,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCard,
    BImg,
  },
  props: {
    page_data: Object,
  },
  data() {
    return {
      group: require("@/assets/images/icons/Group.png"),
      pen: require("@/assets/images/icons/pencil-alt.png"),
      infoImg: require("@/assets/images/icons/info.png"),
      arrowImg: require("@/assets/images/icons/chevron-down.png"),
      infoImg_w: require("@/assets/images/icons/info-w.png"),
      arrowImg_w: require("@/assets/images/icons/arrow-w.png"),
      locked: require("@/assets/images/icons/lock.png"),
      contentImg: null,
      bg_color: null,
      value: 52,
      activeCollapse: null,
    };
  },
  methods: {
    toggleCollapse(lessonsId, is_locked) {
      if (!is_locked) {
        if (this.activeCollapse === lessonsId) {
          this.activeCollapse = null;
        } else {
          this.activeCollapse = lessonsId;
        }
      }
    },
    openSection(lesson_id, section_id) {
      this.$emit("openSection", lesson_id, section_id);
    },
  },
};
</script>

<style scoped lang="scss" src="./_weeksSidebar.scss"></style>
