<template>
  <div class="show-section" v-if="section">
    <SectionNavbarComponent
      @openSection="handleOpneSection"
      :page_data="section"
      :course_id="course_id"
      :lesson_id="localLessonId"
      :section_id="localSectionId"
    />

    <div class="box-content">
      <div class="introduction">
        <h2>{{ $t("g.introduction") }}</h2>
        <p>{{ section.introduction }}</p>
      </div>

      <SectionResourcesComponent
        v-if="!section.is_assignment"
        :page_data="section.resources"
      />
      <SectionAssignmentsComponent
        v-if="section.is_assignment"
        :page_data="section.assignment"
      />
      <SectionAnswersComponent
        v-if="section.is_assignment"
        :page_data="section.assignment"
      />
    </div>
  </div>
</template>

<script>
import SectionNavbarComponent from "../sectionNavbar/SectionNavbar.vue";
import SectionResourcesComponent from "../sectionResources/SectionResources.vue";
import SectionAssignmentsComponent from "../sectionAssignments/SectionAssignments.vue";
import SectionAnswersComponent from "../sectionAnswers/SectionAnswers.vue";

export default {
  name: "ShowSection",
  components: {
    SectionNavbarComponent,
    SectionResourcesComponent,
    SectionAssignmentsComponent,
    SectionAnswersComponent,
  },
  data() {
    return {
      section: null,
      course_id: null,
      localSectionId: null,
      localLessonId: null,
    };
  },
  props: ["braedcrumbHandel", "lesson_id", "section_id"],
  created() {
    this.course_id = this.$route.params.id;
    if (this.localLessonId && this.localSectionId) {
      this.getCourseDetails();
    }
  },
  beforeMount() {
    this.localSectionId = this.section_id;
    this.localLessonId = this.lesson_id;
  },
  watch: {
    lesson_id(newVal, oldVal) {
      this.localLessonId = newVal;
      this.getLessonDetails();
    },
    localSectionId(newVal, oldVal) {
      this.getCourseDetails();
    },
    section_id(newVal, oldVal) {
      this.localSectionId = newVal;
    },
  },
  methods: {
    getCourseDetails() {
      this.$http
        .get(
          `guardian/courses/${this.course_id}/lessons/${this.localLessonId}/sections/${this.localSectionId}`
        )
        .then((res) => {
          if (res.status) {
            this.section = res.data.data;
            if (this.$store.state.BreadCrumb.list.length > 2) {
              // Edit Commit Will use
              this.$store.commit("BreadCrumb/Set_BreadCrumb", {
                index: 2,
                value: {
                  text: res.data.data.name,
                  active: false,
                },
              });
            } else if (this.$store.state.BreadCrumb.list.length == 2) {
              //add commit will use
              this.$store.commit("BreadCrumb/AddBreadcrumb", {
                text: res.data.data.name,
                active: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLessonDetails() {
      this.$http
        .get(`guardian/courses/${this.course_id}/lessons/${this.localLessonId}`)
        .then((res) => {
          if (res.status) {
            this.lesson = res.data.data;
            if (this.$store.state.BreadCrumb.list.length > 1) {
              // Edit Commit Will use
              this.$store.commit("BreadCrumb/Set_BreadCrumb", {
                index: 1,
                value: {
                  text: res.data.data.name,
                  active: true,
                },
              });
            } else if (this.$store.state.BreadCrumb.list.length == 1) {
              //add commit will use
              this.$store.commit("BreadCrumb/AddBreadcrumb", {
                text: res.data.data.name,
                active: true,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleOpneSection(lesson_id, section_id) {
      this.localLessonId = lesson_id;
      this.localSectionId = section_id;
    },
  },
};
</script>

<style scoped lang="scss" src="./_showSection.scss"></style>
