<template>
  <div class="sectionAssignments">
    <b-row class="define-component">
      <span class="px-1 text mt-3">{{ $t("g.instructions") }}</span>
    </b-row>
    <div class="holder">
      <div v-for="(file, index) in page_data.attachments" :key="file.id">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="title">
            <img :src="file.type.includes('image')
                ? require('@/assets/images/icons/photo.png')
                : require('@/assets/images/icons/Group.png')
              " class="image mr-1" />
            <span>{{ file.description.split(".")[0] }}</span>
          </div>
          <a :href="file.path" hidden target="_blank" :id="file.id"></a>
          <div @click="dwonloadFile(file.id)" class="file">
            <span>{{ $t("g.download") }}</span>
            <img :src="require('@/assets/images/icons/save.svg')" />
          </div>
        </div>
        <hr v-if="index != page_data.attachments.length - 1" />
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  name: "SectionAssignments",
  props: ["page_data"],
  components: {
    BRow,
    BCol,
  },
  methods: {
    dwonloadFile(id) {
      document.getElementById(id).click();
    },
  },
};
</script>

<style src="./_sectionAssignments.scss" lang="scss" scoped></style>
